import "bootstrap/dist/css/bootstrap.min.css";
import "./src/scss/index.scss";
import { RecoilRoot } from "recoil";
import smoothscroll from "smoothscroll-polyfill";
import React from "react";
import "firebase/auth"
import "firebase/firestore"
import 'firebase/storage'
// import "firebase/functions"
// import Rellax from "rellax";
// import { configBall } from "./src/lib/ball/config";
// import { IsMobileDevice } from "./src/utils/functions";

smoothscroll.polyfill();

export const wrapRootElement = ({ element }) => {
  return <RecoilRoot>{element}</RecoilRoot>;
};
// export const onInitialClientRender = () => {
//   //console.log(IsMobileDevice());
//   if (window.BlobAnimation){

//     new window.BlobAnimation("ball", configBall(IsMobileDevice() ? 150 : 400));
//   }
//   new Rellax(".rellaxnumfirst", {
//     // <---- Via class name
//     speed: 0.2,
//     center: false,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaxnumfirstmobile", {
//     // <---- EN CERO
//     speed: -0,
//     center: false,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaxtitlefirst", {
//     // <---- Via class name
//     speed: 0.15,
//     center: false,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaxtitlefirstmobile", {
//     // <---- EN CERO
//     speed: -0,
//     center: false,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaxtextfirst", {
//     // <---- Via class name
//     speed: 0.2,
//     center: false,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaxtextfirstmobile", {
//     // <---- EN CERO
//     speed: -0,
//     center: true,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaximgfirst", {
//     // <---- Via class name
//     speed: 0.45,
//     center: false,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaximgfirstmobile", {
//     // <---- EN CERO
//     speed: -0,
//     center: true,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaxnummiddle", {
//     // <---- Via class name
//     speed: 0.45,
//     center: true,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaxnummobile", {
//     // EN CERO
//     speed: 0,
//     center: false,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });

//   new Rellax(".rellaxtitlemiddle", {
//     // <---- Via class name
//     speed: 0.3,
//     center: true,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaxtextmiddle", {
//     // <---- Via class name
//     speed: 0.3,
//     center: true,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaximgmiddle", {
//     // <---- Via class name
//     speed: -0.3,
//     center: true,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });

//   new Rellax(".rellaxtitlelast", {
//     // <---- Via class name
//     speed: -0.42,
//     center: true,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaxtextlast", {
//     // <---- Via class name
//     speed: -0.38,
//     center: true,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaxnumlast", {
//     // <---- Via class name
//     speed: -0.65,
//     center: true,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   new Rellax(".rellaximglast", {
//     // <---- Via class name
//     speed: -0.65,
//     center: true,
//     wrapper: null,
//     round: true,
//     vertical: true,
//     horizontal: false,
//   });
//   //console.log("ReactDOM.render has executed HOOOOOOOOOOOLAAAAAAA");
// };
